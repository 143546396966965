import React  from 'react';
import './Playlist.scss';

export const Playlist = () => {
    const playListFrameStyle = {
        backgroundImage: `url('${process.env.PUBLIC_URL}/playlist-background-2.svg')`,
        backgroundSize: '500% 100%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    }
    
    return (
        <div className="playlist section">
            <div className="playlist-frame" style={playListFrameStyle}>
                <div className="playlist-content">
                    <iframe
                        className={'playlist-iframe'}
                        
                        src={`https://open.spotify.com/embed/album/1qhRenziCd3qBtqNT2SOYZ?utm_source=generator`}
                        title={'Playlist'}
                        frameBorder="0"
                        allowFullScreen={false}
                        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                        loading="lazy"></iframe>


                </div>
            </div>
            
        </div>
    )
};
