import React from 'react';
import './header.scss';

export const Header = () => {

    return (
        <div className="header section">
            <div className="header-left">
                <img src="/header-left.svg" alt="header-left"/>
            </div>
            <div className="header-middle">
                <div className="header-image"><img src="/header-main.svg" alt="pop picon"/> </div>

            </div>
            <div className="header-right">
                <img src="/header-right.svg" alt="placeholder-right"/>
            </div>
        </div>
    );
};
