import React from 'react';
import './About.scss';
import { MuyaioSocial } from '../buttons/MuyaioSocial/muyaioSocial';

export const About = () => {
    return (
        <div className="about section">
            <div className="section-wrapper">
                <div className="section-content">
                    <div className="about-row">
                        <div className="about-left">
                            <img src="/muyaio-left.png" alt="Estrellas"/>
                        </div>
                        <div className="about-text">
                            <div className="section-title">
                                <img src="/muyaio-title.svg" alt="Muyaio"/>
                            </div>
                            <span>
                            Soy Muyaio, músico y algoritmólogo.
                                Te presento Pop Picón, mi segundo álbum, 
                                y junto él una IA desarrollada por mí. 
                                Un fanzine interactivo donde puedes charlar con los 
                                personajes del álbum: Julio Iglesias, Manu Chao, 
                                Kurt Cobain o Shakira, recibir recomendaciones musicales o de cine,
                                debatir sobre identidad de género o los peligros de la IA,
                                o descubrir cosas sobre Canarias. 
                            <br/><br/>
                                Aquí te cuento <a href="https://muyaio.com/2023/01/31/algoderitmo-recomendacion-musical-para-todos/" target="_blank" rel="noreferrer" >como lo hice</a>, 
                                y aquí <a href="https://drive.google.com/file/d/1df1WFCMOubGxX7U5VjP07Hd4Zr4xQPZv/view?usp=sharing" target="_blank" rel="noreferrer" >más cosas sobre el álbum</a>.
                            <br/><br/>
                            Me encuentras también en:
                            </span>
                            <div className="about-share">
                                <MuyaioSocial/>
                            </div>
                        </div>
                        <div className="about-image">
                            <img src="/muyaio-image.svg" alt="Muyaio foto"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
