import React from 'react';
import './Postadas.scss';

export const Postadas = () => {

    return (
        <div className="postadas section">
            <div className="postadas-header">
                <img src="/singles-title.svg" alt="Singles" className="postadas-header-image"></img>
            </div>
            <div className="postadas-content">
                <div className="postadas-postada">
                    <a href="https://open.spotify.com/intl-es/track/321IhvHTlSNKM2heygOId6?si=7c135badafca4574" target="_blank" rel="noreferrer" >
                        <img src="/single-1.png" alt="El Algoritmo" className="postadas-postada-image"></img>
                    </a>
                </div>
                <div className="postadas-postada">
                    <a href="https://open.spotify.com/intl-es/track/0PJaSCe4LL3Ah2O8yhQnd5?si=6dea569adc2940d1" target="_blank" rel="noreferrer">
                        <img src="/single-2.png" alt="Se Dio Así" className="postadas-postada-image"></img>
                    </a>
                </div>
                <div className="postadas-postada">
                    <a href="https://open.spotify.com/intl-es/track/4rA1ZLN49ERVNKM0eRygkS?si=698c5b600fe242d3" target="_blank" rel="noreferrer">
                        <img src="/single-3.png" alt="Chiquito Pelete" className="postadas-postada-image"></img>
                    </a>
                </div>
                <div className="postadas-postada">
                    <a href="https://open.spotify.com/intl-es/track/7c5wA7Rti7ZhGNHjwZdhzR?si=995c9c7cb76348bf" target="_blank" rel="noreferrer">
                        <img src="/single-4.png" alt="Quiero Ser Como Manu Chao" className="postadas-postada-image"></img>
                    </a>
                </div>
                <div className="postadas-postada">
                    <a href="https://open.spotify.com/intl-es/track/5dh2BFJiIMhOvgbllr6N3b?si=fb3044e93f95448e" target="_blank" rel="noreferrer">
                        <img src="/single-5.png" alt="Soñé con Julio" className="postadas-postada-image"></img>
                    </a>
                </div>
            </div>

        </div>
    )
};
