import React from 'react';
import './Video.scss';

export const Video = () => {
   
    
    return (
        <div className="video section">
            <div className="video-title section-title">
                <img src="/video-header.svg" alt="video 1" className="video-header"></img>
            </div>
            <div className="video-main">
                       
                <div className="video-player video-1">
                    <div className="video-player-header">
                        <img src="/video-1-header.svg" alt="video 1" className="video-player-header"></img>
                    </div>
                    <iframe src="https://www.youtube.com/embed/goDqxf29-Cw?si=aIUlcVVd_I_7s4L-"
                            title="Muyaio - El Algoritmo" frameBorder="0"
                            referrerPolicy="origin"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                </div>
                <div className="video-player video-2">
                    <div className="video-player-header">
                        <img src="/video-2-header.svg" alt="video 2" className="video-player-header"></img>
                    </div>
                    <iframe src="https://www.youtube.com/embed/BJYJGdGZphY?si=6D9fx_wwawW_gcp8"
                            title="Muyaio, Abel de Los Vinagres - Chiquito Pelete" frameBorder="0"
                            referrerPolicy="origin"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                </div>
                <div className="video-player video-3">
                    <div className="video-player-header">
                        <img src="/video-3-header.svg" alt="video 3" className="video-player-header"></img>
                    </div>
                    <iframe src="https://www.youtube.com/embed/XIZ6SFtWe2g?si=e4a8QQfnSk0rFbqq"
                            title="Muyaio, Joan Colomo - Soñé con Julio" frameBorder="0"
                            referrerPolicy="origin"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                </div>
                <div className="video-player video-4">
                    <div className="video-player-header">
                        <img src="/video-4-header.svg" alt="video 4" className="video-player-header"></img>
                    </div>
                    <iframe src="https://www.youtube.com/embed/zykkosMYr94"
                            title="Muyaio - Chica Analógica ft. Angélica Pérez y Fran Baraja" frameBorder="0"
                            referrerPolicy="origin"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                </div>
                
                
            </div>
        </div>
    )
};
