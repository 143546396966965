import React from 'react';
import './chat.scss';

export const Chat = () => {

    return (
        <div className="chat section">
            <div className="chat-content">
                <iframe src="chat/index.html"></iframe>
            </div>
            <div className="chat-zine-whats">
                <a href="https://wa.me/34615419869" target="_blank" rel="noreferrer">
                    <img src="/chat-zine-whatsapp.png" alt="chat zine" className="chat-zine-image"></img>
                </a>
            </div>
        </div>
    );
};
