import React from 'react';
import './Footer.scss';
import {MuyaioSocial} from '../buttons/MuyaioSocial/muyaioSocial';

export const Footer = () => {

    return (
        <div className="footer section">
            <div className="footer-left">
                <img src="/footer-left.svg" alt="footer left" className="footer-left-image"></img>
            </div>
            <div className="footer-middle">
                <div className="footer-sergio">Sergio Oramas</div>
                <div className="footer-email">esemuyaio[at]gmail.com</div>
                <div  className="footer-copyright">
                    <img src="/cc.png" alt="cc" className="footer-cc"></img>
                </div>
                <div className="footer-helpers">
                    <div className="footer-helper">Developed by Bas</div>
                    <div className="footer-helper">Ilustrated by Conxita Herrero</div>
                    <div className="footer-helper">Design by <a href="https://opendesignstudio.eu/">OpenDesignStudio</a></div>
                </div>
                <div className="footer-social">
                    <MuyaioSocial/>
                </div>
            </div>
            <div className="footer-right">
                <img src="/footer-right.svg" alt="footer left" className="footer-right-image"></img>
            </div>
        </div>
    );
};
