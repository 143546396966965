import React from 'react';
import './App.scss';
import {Video} from './components/video/Video';
import {About} from './components/about/About';
import {Header} from './components/header/header';
import {Chat} from "./components/chat/chat";
import {Postadas} from "./components/postadas/Postadas";
import {Footer} from "./components/footer/Footer";
import {Playlist} from "./components/playlist/Playlist";

function App() {
    
    return (
        <div className="app">
            <div className="app-content">
                <Header></Header>
                <Playlist></Playlist>
                <Chat></Chat>
                <Video></Video>
                <About></About>
                <Postadas></Postadas>
                <Footer></Footer>
                
            </div>
        </div>
    );
}

export default App;
